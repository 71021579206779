<template>
    <div class="profile-card-container">{{ props.text }}</div>
</template>
<script setup lang="ts">
import { defineProps } from 'vue';

const props = defineProps(["text"])

</script>
<style scoped>
.profile-card-container {
    background-color: var(--profile-card-background-color);
    color: var(--profile-card-color);
    border-radius: 15px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>