<template>
    <div class="home">
        <div class="profile-container">
            <div class="profile-container-image-text">
                <img src="@/assets/user.png" style="width: 100px;">
                <div class="profile-container-text">
                    <div>
                        Tom Trappmmann
                    </div>
                    <div>
                        DiMaS No. #25168
                    </div>
                    <div>
                        Aktive seit 2024
                    </div>
                </div>
            </div>
            <div>
                <the-credit-table :data="credits"></the-credit-table>
            </div>
        </div>
        <div class="card-container">
            <the-main-card v-for="menuPoint, index in menuPoints" :key="index" :icon="menuPoint.icon" :number="22"
                :title="menuPoint.name"></the-main-card>
        </div>
    </div>
</template>

<script setup lang="ts">
import TheMainCard from '@/components/TrainerCenter/TheMainCard.vue';
import TheCreditTable from '@/components/TrainerCenter/TheCreditTable.vue';
import { faUser, faAddressCard, faCircleInfo, faPrint, faIdCardClip, faGraduationCap, faClipboardList, faPenToSquare } from '@fortawesome/free-solid-svg-icons';


const menuPoints = [
    {
        name: "Meine Schüler",
        icon: faUser,
    }, {
        name: "Brevetierungen",
        icon: faAddressCard,
    }, {
        name: "Formulare",
        icon: faCircleInfo,
    }, {
        name: "Druckaufträge",
        icon: faPrint,
    }, {
        name: "Kurse",
        icon: faIdCardClip,
    }, {
        name: "E-Learning",
        icon: faGraduationCap,
    }, {
        name: "Checkliste",
        icon: faClipboardList,
    }, {
        name: "Exam",
        icon: faPenToSquare,
    },
]
const credits = [
    { organization: "Meine", amount: "0Ç" },
    { organization: "IDDA Academy", amount: "100Ç" },
    { organization: "VTM-Dive", amount: "890Ç" }
]
</script>
<style lang="css" scoped>
.card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.profile-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
}

.profile-container-image-text {
    display: flex;
    align-items: center;
}

.profile-container-text {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
</style>