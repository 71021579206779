<template>
    <div class="title-container">
        <img src="@/assets/sample_news.jpg" style="width: 300px;">
        <h3 class="title">{{ props.title }}</h3>
        <BasicButton>Read More</BasicButton>
    </div>
</template>
<script lang="ts" setup>
import { defineProps } from 'vue';
import BasicButton from '../basic/BasicButton.vue';
const props = defineProps(["title", "img"])

</script>
<style lang="css" scoped></style>