<template>
    <div class="credit-table-container">
        <table class="credit-table">
            <thead>
                <tr>
                    <th>Organization</th>
                    <th>Credits</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(credit, index) in props.data" :key="index">
                    <td>{{ credit.organization }}</td>
                    <td>{{ credit.amount }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup>

import { defineProps } from 'vue';
const props = defineProps(["data"])
</script>

<style scoped>
.credit-table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
}

.credit-table {
    border-collapse: collapse;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.credit-table th,
.credit-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

.credit-table th {
    background-color: var(--trainer-card-background-color);
    color: white;
}

.credit-table tr:nth-child(even) {
    background-color: #f2f2f2;
}
</style>