<template>
    <div class="container">

        <div class="title-search-container">
            <h2>{{ props.title }}</h2>
            <div class="search-container">
                <input type="text" placeholder="Search..." v-model="searchValue" @change="search()">
            </div>
        </div>
        <basic-table :data="data" :loading="loading" :cols="props.cols"></basic-table>
        <div class="pagination-container">
            <label>
                <select v-model="selectedRowNumber" @change="changeNumPage()">
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="150">150</option>
                </select>
            </label>
            <div class="pagination">
                <button v-if="currentPage != 1" @click="prevPage()">&laquo;</button>
                <button class="active">{{ currentPage }}</button>
                <button v-if="currentPage != lastPage" @click="nextPage()">&raquo;</button>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import BasicTable from './basic/BasicTable.vue'
import { defineProps, computed, ref, watch } from 'vue';
import axios from 'axios';
import { BASE_URL } from '@/resources/api';
import { useLoginStore } from '@/stores/login';

const props = defineProps(['title', 'cols', 'apiRoute'])
const loading = ref(true)
const data = ref({ numberRows: 1 })
const loginStore = useLoginStore()
const selectedRowNumber = ref(25)
const currentPage = ref(1)
const searchValue = ref("")
const header = {
    headers: {
        Authorization: `Bearer ${loginStore.token}`,
    }
}

watch(props, () => {
    loadData()
})

const loadData = () => {
    axios.get(`${BASE_URL}${props.apiRoute}?start=${(currentPage.value * selectedRowNumber.value) - selectedRowNumber.value}&limit=${selectedRowNumber.value}&search=${searchValue.value}`, header).then(response => {
        data.value = response.data
        loading.value = false
    }).catch(error => {
        console.log(error)
    })
}

const lastPage = computed(() => {
    return Math.ceil(data.value.numberRows / selectedRowNumber.value)
})


const nextPage = () => {
    currentPage.value += 1
    loadData()
}
const prevPage = () => {
    currentPage.value -= 1
    loadData()
}

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth' // Optional: Add smooth scrolling behavior
    });
};

const changeNumPage = () => {
    currentPage.value = 1
    scrollToTop()
    loadData()
}

const search = () => {
    currentPage.value = 1
    scrollToTop()
    loadData()
}

loadData()
</script>
<style scoped>
.container {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
}

.title-search-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0px 15px;
    margin-top: 20px;
}

h2 {
    font-size: 26px;
    margin: 10px 40px;
    text-align: left;
}

input[type="text"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    width: 200px;
}

label {
    position: relative;
    display: inline-block;
}

label:before {
    content: "";
    height: 31px;
    position: absolute;
    right: 7px;
    top: 3px;
    width: 22px;
    background: #fff;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    pointer-events: none;
    display: block;
}

label:after {
    content: " ";
    position: absolute;
    right: 15px;
    top: 46%;
    margin-top: -3px;
    z-index: 2;
    pointer-events: none;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6.9px 4px 0 4px;
    border-color: #aaa transparent transparent transparent;
    pointer-events: none;
}

label select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0 30px 0 10px;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    line-height: 36px;
    height: 36px;
    background: #fff;
    margin: 0 5px 5px 0;
}

select::-ms-expand {
    display: none;
}

.pagination {
    display: inline-block;
}

.pagination button {
    padding: 8px 16px;
    text-decoration: none;
    border: none;
}

.pagination button.active {
    background-color: var(--primary-color);
    color: var(--primary-color-text);
}

.pagination button:hover:not(.active) {
    background-color: #ddd;
}

.pagination-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 50px;
}
</style>