<template>
    <div>
        <a>Kontakt</a> | <a>Datenschutz</a> | <a>IDDA Webseite</a> | <span>Trainer Verifizierung</span> |
        <span>Taucher
            Verifizierung</span>
        <div style="margin-top: 10px;">DiMaS v5 (©) {{ currentYear() }} by Mr. Dehne | All rights reserved</div>
    </div>
</template>
<script setup lang="ts">
const currentYear = (): string => {
    const current = new Date()
    const date = `${current.getFullYear()}`
    return date;
}
</script>