<template>
    <div class="profile-container">
        <div>
            <img src="@/assets/user.png" style="width: 100px;">
            <div><font-awesome-icon :icon="faRepeat" style="width: 20px;" />Change Picker</div>
            <div>
                <the-profile-card text="Medizinischer Fragebogen"></the-profile-card>
                <the-profile-card text="Haftungsausschluss"></the-profile-card>
                <the-profile-card text="Konfektionsgrößen"></the-profile-card>
            </div>
        </div>

        <div>
            Tom Trappmmann
            DiMaS No. #25168
        </div>
        <div>
            <basic-select :options="languages" value="es"></basic-select>
        </div>
        <div>

            <div>
                <div>E-Mail</div>
                <basic-input type="email" placholder="E-Mail"></basic-input>
            </div>
            <div>
                <div>Telefon</div>
                <basic-input type="phone" placholder="Telefon"></basic-input>
            </div>
            <div>
                <div>Gebursdatum</div>
                <basic-input type="date" placholder="Gebursdatum"></basic-input>
            </div>
            <div>
                <div>Straße / Nr</div>
                <basic-input type="text" placholder="Straße / Nr"></basic-input>
            </div>
            <div>
                <div>Postleitzahl</div>
                <basic-input type="text" placholder="Postleitzahl"></basic-input>
            </div>
            <div>
                <div>Stadt</div>
                <basic-input type="text" placholder="Stadt"></basic-input>
            </div>
            <div>
                <div>Land</div>
            </div>
            <div>
                <div>Newsletter</div>
            </div>
            <div>
                <div>Kordinaten</div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faRepeat } from '@fortawesome/free-solid-svg-icons';
import TheProfileCard from '@/components/profile/TheProfileCard.vue';
import BasicInput from '@/components/basic/BasicInput.vue';
import BasicSelect from '@/components/basic/BasicSelect.vue';



const languages = [
    {
        "value": "es",
        "text": "Spansih",
    },
    {
        "value": "en",
        "text": "Englisch",
    },
    {
        "value": "de",
        "text": "Deutsch",
    }

]

</script>
<style lang="css" scoped>
.profile-container {
    display: flex;
}
</style>