<!-- eslint-disable vue/html-indent -->
<template>
    <div>
        <div class="header-menu">
            <img style="height: 50px;" src="@/assets/logo_white.png">
            <div></div>
            <the-burger-menu @click="switchNav()" class="burger-menu"
                :class="{ navactiv: activeNav }"></the-burger-menu>
        </div>
        <div class="nav-bar" :class="{ navactiv: activeNav }">
            <div class="nav-list">
                <template v-for="menuPoint, index in menuPoints" :key="index">
                    <the-menu-item v-if="!menuPoint.children" :to="menuPoint.path" :text="menuPoint.name"
                        :icon="menuPoint.icon" @click="switchNav()"></the-menu-item>
                    <the-menu-item-nested v-else-if="menuPoint.children" :to="menuPoint.path" :text="menuPoint.name"
                        :icon="menuPoint.icon">
                        <template v-for="menuPointCildren, indexChildren in menuPoint.children" :key="indexChildren">
                            <the-menu-item :to="menuPoint.path + menuPointCildren.path" :text="menuPointCildren.name"
                                :icon="menuPointCildren.icon" @click="switchNav()"></the-menu-item>
                        </template>
                    </the-menu-item-nested>
                </template>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import TheMenuItem from '@/components/menu/TheMenuItem.vue'
import TheMenuItemNested from '@/components/menu/TheMenuItemNested.vue'
import TheBurgerMenu from '@/components/menu/TheBurgerMenu.vue'
import { faHouse, faUser, faAddressCard, faBookOpen, faCloudArrowDown, faMapLocationDot, faFileLines, faShoppingCart, faUserGroup, faSchool, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { ref } from 'vue';

const activeNav = ref(false)

const menuPoints = [
    {
        name: "Home",
        path: "/home",
        icon: faHouse,
    },
    {
        name: "Mein Profil",
        path: "/profile",
        icon: faUser,
    },
    {
        name: "Meine Brevetierungen",
        path: "/brevets",
        icon: faAddressCard,
    },
    {
        name: "Meine Kurse",
        path: "/courses",
        icon: faBookOpen,
    },
    {
        name: "Meine Downloads",
        path: "/downloads",
        icon: faCloudArrowDown,
    },
    {
        name: "IDDA in der Nähe",
        path: "/map",
        icon: faMapLocationDot,
    },
    {
        name: "Standards",
        path: "/standards",
        icon: faFileLines,
    },
    {
        name: "Shop",
        path: "/shop",
        icon: faShoppingCart,
    },
    {
        name: "Trainerarea",
        path: "/trainer",
        icon: faUserGroup,
    },
    {
        name: "Centerarea",
        path: "/center",
        icon: faSchool,
    },
    {
        name: "statistic",
        path: "/statistic",
        icon: faChartLine,
    },
    {
        name: "Admin",
        path: "/admin",
        icon: "admin",
        children: [
            {
                name: "Center",
                path: "/center",
                icon: "center",
            },
            {
                name: "Taucher",
                path: "/diver",
                icon: "diver",
            },
            {
                name: "Trainer",
                path: "/trainer",
                icon: "trainer",
            },
            {
                name: "Brevetierungen",
                path: "/brevet",
                icon: "brevet",
            },
            {
                name: "Profile",
                path: "/profile",
                icon: "profile",
            },
            {
                name: "News",
                path: "/news",
                icon: "news",
            },
            {
                name: "Druck aufträge",
                path: "/print",
                icon: "print",
            },
            {
                name: "Verifizieren",
                path: "/verifikation",
                icon: "verifikation",
            },
            {
                name: "Transformationen",
                path: "/transformation",
                icon: "transformation",
            },
            {
                name: "Fragebögen",
                path: "/quesquestionnaire",
                icon: "questionnaire",
            },
            {
                name: "Pay Later",
                path: "/paylater",
                icon: "paylater",
            },
            {
                name: "Pay Later History",
                path: "/paylaterhistory",
                icon: "paylaterhistory",
            },
            {
                name: "Download",
                path: "/download",
                icon: "download",
            },
            {
                name: "Downloadgruppen",
                path: "/downloadgroup",
                icon: "downloadgroup",
            },
            {
                name: "Shop",
                path: "/shop",
                icon: "shop",
            },
            {
                name: "Kurse",
                path: "/course",
                icon: "course",
            },
            {
                name: "Kursgruppen",
                path: "/coursegroup",
                icon: "coursegroup",
            },
            {
                name: "Book",
                path: "/book",
                icon: "book",
            },
            {
                name: "Exam",
                path: "/exam",
                icon: "exam",
            },
            {
                name: "Standards",
                path: "/standard",
                icon: "standard",
            },
            {
                name: "Standard Typen",
                path: "/standardtyp",
                icon: "standardtyp",
            },
            {
                name: "Checklisten",
                path: "/checklist",
                icon: "checklist",
            },
        ]
    },
]

const switchNav = function () {
    activeNav.value = !activeNav.value
}

menuPoints.find(point => point.name === "Admin")?.children?.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
});
</script>
<style scoped>
.burger-menu {
    transition: 0.5s !important;
    width: 100px;
    height: 100px;
}

.header-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    color: var(--nav-header-text-color);
    background-color: var(--nav-header-background-color);
    height: 60px;
}

.title {
    text-align: start;
    padding: 5px;
}

.nav-bar {
    z-index: 11;
    background-color: var(--nav-side-bar-background-color);
    color: var(--nav-side-bar-text-color);
    left: -250px;
    top: 80px;
    height: 100%;
    width: 200px;
    transition: 0.5s;
    padding: 15px;
    overflow: scroll;
    position: fixed;
}

.nav-bar.navactiv {
    transform: translate(250px, 0) !important;
}

.nav-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: start;
}
</style>
