import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import { useLoginStore } from "@/stores/login";


import LoginView from "@/views/LoginView.vue";

import BaseView from "@/views/BaseView.vue";

import HomeView from "@/views/HomeView.vue";

// Admin Views
import BookView from "@/views/admin/BookView.vue";
import BrevetView from "@/views/admin/BrevetView.vue";
import CenterView from "@/views/admin/CenterView.vue";
import ChecklistView from "@/views/admin/ChecklistView.vue";
import CourseView from "@/views/admin/CourseView.vue";
import CourseGroupView from "@/views/admin/CourseGroupView.vue";
import DiverView from "@/views/admin/DiverView.vue";
import DownloadGroupView from "@/views/admin/DownloadGroupView.vue";
import DownloadView from "@/views/admin/DownloadView.vue";
import ExamView from "@/views/admin/ExamView.vue";
import NewsView from "@/views/admin/NewsView.vue";
import PayLaterHistoryView from "@/views/admin/PayLaterHistoryView.vue";
import PayLaterView from "@/views/admin/PayLaterView.vue";
import PrintView from "@/views/admin/PrintView.vue";
import ProfileView from "@/views/admin/ProfileView.vue";
import QuesquestionnaireView from "@/views/admin/QuesquestionnaireView.vue";
import ShopView from "@/views/admin/ShopView.vue";
import StandardTypView from "@/views/admin/StandardTypView.vue";
import StandardView from "@/views/admin/StandardView.vue";
import TrainerView from "@/views/admin/TrainerView.vue";
import TransformationView from "@/views/admin/TransformationView.vue";
import VerificationView from "@/views/admin/VerificationView.vue";

import MyProfile from "@/views/ProfileView.vue"
import MyBrevetView from "@/views/BrevetView.vue"
import MyCourse from "@/views/CourseView.vue"
import MyDownload from "@/views/DownloadView.vue"
import MyMap from "@/views/MapView.vue"
import MyStandardView from "@/views/StandardView.vue"
import MyShop from "@/views/ShopView.vue"
import MyTrainerArea from "@/views/TrainerView.vue"
import MyCenterArea from "@/views/CenterView.vue"
import MyStatistics from "@/views/StatisticsView.vue"

import StandardDetailView from "@/views/standard/StandardDetailView.vue";


const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/home",
    component: BaseView,
    children: [
      {
        path: "/home",
        name: "home",
        component: HomeView,
        meta: { requiresAuth: true, requiresAdmin: false, requiresTrainer: false, requiresCenter: false },
      },
      {
        path: "/profile",
        name: "profile",
        component: MyProfile,
        meta: { requiresAuth: true, requiresAdmin: false, requiresTrainer: false, requiresCenter: false },
      },
      {
        path: "/brevets",
        name: "brevets",
        component: MyBrevetView,
        meta: { requiresAuth: true, requiresAdmin: false, requiresTrainer: false, requiresCenter: false },
      },
      {
        path: "/downloads",
        name: "download",
        component: MyDownload,
        meta: { requiresAuth: true, requiresAdmin: false, requiresTrainer: false, requiresCenter: false },
      },
      {
        path: "/courses",
        name: "course",
        component: MyCourse,
        meta: { requiresAuth: true, requiresAdmin: false, requiresTrainer: false, requiresCenter: false },
      },
      {
        path: "/map",
        name: "map",
        component: MyMap,
        meta: { requiresAuth: true, requiresAdmin: false, requiresTrainer: false, requiresCenter: false },
      },
      {
        path: "/standards/:standard_id?/:module?",
        name: "standards",
        component: MyStandardView,
        meta: { requiresAuth: true, requiresAdmin: false, requiresTrainer: true, requiresCenter: true },
      },
      {
        path: "/shop",
        name: "shop",
        component: MyShop,
        meta: { requiresAuth: true, requiresAdmin: false, requiresTrainer: true, requiresCenter: true },
      },
      {
        path: "/trainer",
        name: "trainer",
        component: MyTrainerArea,
        meta: { requiresAuth: true, requiresAdmin: false, requiresTrainer: true, requiresCenter: false },
      },
      {
        path: "/center",
        name: "center",
        component: MyCenterArea,
        meta: { requiresAuth: true, requiresAdmin: false, requiresTrainer: false, requiresCenter: true },
      },
      {
        path: "/statistics",
        name: "statistics",
        component: MyStatistics,
        meta: { requiresAuth: true, requiresAdmin: false, requiresTrainer: false, requiresCenter: false },
      },
      {
        path: "/admin",
        name: "admin",
        meta: { requiresAuth: true, requiresAdmin: true, requiresTrainer: false, requiresCenter: false },
        children: [
          {
            path: "book",
            name: "admin-book",
            component: BookView,
            meta: { requiresAuth: true, requiresAdmin: true, requiresTrainer: false, requiresCenter: false },
          },
          {
            path: "brevet",
            name: "admin-brevet",
            component: BrevetView,
            meta: { requiresAuth: true, requiresAdmin: true, requiresTrainer: false, requiresCenter: false },
          },
          {
            path: "center",
            name: "admin-center",
            component: CenterView,
            meta: { requiresAuth: true, requiresAdmin: true, requiresTrainer: false, requiresCenter: false },
          },
          {
            path: "checklist",
            name: "admin-checklist",
            component: ChecklistView,
            meta: { requiresAuth: true, requiresAdmin: true, requiresTrainer: false, requiresCenter: false },
          },
          {
            path: "course",
            name: "admin-course",
            component: CourseView,
            meta: { requiresAuth: true, requiresAdmin: true, requiresTrainer: false, requiresCenter: false },
          },
          {
            path: "coursegroup",
            name: "admin-coursegroup",
            component: CourseGroupView,
            meta: { requiresAuth: true, requiresAdmin: true, requiresTrainer: false, requiresCenter: false },
          },
          {
            path: "diver",
            name: "admin-diver",
            component: DiverView,
            meta: { requiresAuth: true, requiresAdmin: true, requiresTrainer: false, requiresCenter: false },
          },
          {
            path: "downloadgroup",
            name: "admin-downloadgroup",
            component: DownloadGroupView,
            meta: { requiresAuth: true, requiresAdmin: true, requiresTrainer: false, requiresCenter: false },
          },
          {
            path: "download",
            name: "admin-download",
            component: DownloadView,
            meta: { requiresAuth: true, requiresAdmin: true, requiresTrainer: false, requiresCenter: false },
          },
          {
            path: "exam",
            name: "admin-exam",
            component: ExamView,
            meta: { requiresAuth: true, requiresAdmin: true, requiresTrainer: false, requiresCenter: false },
          },
          {
            path: "news",
            name: "admin-news",
            component: NewsView,
            meta: { requiresAuth: true, requiresAdmin: true, requiresTrainer: false, requiresCenter: false },
          },
          {
            path: "paylaterhistory",
            name: "admin-paylaterhistory",
            component: PayLaterHistoryView,
            meta: { requiresAuth: true, requiresAdmin: true, requiresTrainer: false, requiresCenter: false },
          },
          {
            path: "paylater",
            name: "admin-paylater",
            component: PayLaterView,
            meta: { requiresAuth: true, requiresAdmin: true, requiresTrainer: false, requiresCenter: false },
          },
          {
            path: "print",
            name: "admin-print",
            component: PrintView,
            meta: { requiresAuth: true, requiresAdmin: true, requiresTrainer: false, requiresCenter: false },
          },
          {
            path: "profile",
            name: "admin-profile",
            component: ProfileView,
            meta: { requiresAuth: true, requiresAdmin: true, requiresTrainer: false, requiresCenter: false },
          },
          {
            path: "quesquestionnaire",
            name: "admin-quesquestionnaire",
            component: QuesquestionnaireView,
            meta: { requiresAuth: true, requiresAdmin: true, requiresTrainer: false, requiresCenter: false },
          },
          {
            path: "shop",
            name: "admin-shop",
            component: ShopView,
            meta: { requiresAuth: true, requiresAdmin: true, requiresTrainer: false, requiresCenter: false },
          },
          {
            path: "standardtyp",
            name: "admin-standardtyp",
            component: StandardTypView,
            meta: { requiresAuth: true, requiresAdmin: true, requiresTrainer: false, requiresCenter: false },
          },
          {
            path: "standard",
            name: "admin-standard",
            component: StandardView,
            meta: { requiresAuth: true, requiresAdmin: true, requiresTrainer: false, requiresCenter: false },
          },
          {
            path: "trainer",
            name: "admin-trainer",
            component: TrainerView,
            meta: { requiresAuth: true, requiresAdmin: true, requiresTrainer: false, requiresCenter: false },
          },
          {
            path: "transformation",
            name: "admin-transformation",
            component: TransformationView,
            meta: { requiresAuth: true, requiresAdmin: true, requiresTrainer: false, requiresCenter: false },
          },
          {
            path: "verification",
            name: "admin-verification",
            component: VerificationView,
            meta: { requiresAuth: true, requiresAdmin: true, requiresTrainer: false, requiresCenter: false },
          },
        ],
      },

    ],
    meta: { requiresAuth: true, requiresAdmin: false, requiresTrainer: false, requiresCenter: false },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: { requiresAuth: false, requiresAdmin: false, requiresTrainer: false, requiresCenter: false },
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  const loginStore = useLoginStore();
  if (to.meta.requiresAuth && !loginStore.isLoggedIn()) return "/login";
  if (to.meta.requiresTrainer && !loginStore.isLoggedIn() || to.meta.requiresCenter && !loginStore.isLoggedIn()) return "/home";
  if (to.meta.requiresAdmin && !loginStore.isLoggedIn()) return "/home";

});

export default router;
