<template>
    <div class="title-container">
        <h1 class="title">{{ props.title }}</h1>
    </div>
</template>
<script lang="ts" setup>
import { defineProps } from 'vue';
const props = defineProps(["title"])

</script>
<style lang="css" scoped>
.title {
    color: var(--title-color);
}

.title-container {
    width: 80%;
    border-radius: 15px;
    height: 60px;
    background-color: var(--title-background-color);
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>