<template>
    <div class="elearing-menu-container">
        <div class="elearing-menu-list">
            <BasicLeaveButton to="/standards">Leave
                Standards</BasicLeaveButton>
            <template v-for="menuPoint, index in menuPoints" :key="index">
                <TheELearningMenuItem :to="'/standards/' + route.params.standard_id + menuPoint.path"
                    :text="menuPoint.name">
                </TheELearningMenuItem>
            </template>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineProps, ref } from 'vue';
import TheELearningMenuItem from './TheELearningMenuItem.vue';
import { useRoute } from 'vue-router';
import BasicLeaveButton from '../basic/BasicLeaveButton.vue';

const route = useRoute()
const props = defineProps(["meunIteams"])

const menuPoints = [
    {
        name: "Home",
        path: "/home",

    },
    {
        name: "Mein Profil",
        path: "/profile",
    },
]

</script>
<style scoped>
.elearing-menu-container {
    z-index: 10;
    background-color: var(--nav-side-bar-background-color);
    color: var(--nav-side-bar-text-color);
    left: 0px;
    top: 80px;
    height: 100%;
    width: 200px;
    transition: 0.5s;
    padding: 15px;
    overflow: scroll;
    position: fixed;
}

.elearing-menu-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: start;
}
</style>